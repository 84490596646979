html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiInputLabel-root.MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
  font-size: 14px;
  transform: translate(10px, -12px);
}

.MuiFilledInput-root.MuiFilledInput-underline::before {
  border: none !important;
}

.MuiFormControl-root.MuiTextField-root {
  border-radius: 6px;
}

.MuiInputBase-formControl {
  background-color: white !important;
  border-radius: 6px !important;
}

.tab-container {
  padding: 10px 10px 0 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .tab {
    flex: 1;
    padding: 25px;
    font-weight: 600;
    height: 100%;
    cursor: pointer;
    border-radius: 4px 4px 0 0;
    background: rgba(251, 251, 252, 0.2);

    p, svg path {
      color: white;
    }

    &.active {
      font-weight: 700;
      background-color: white;

      p, svg path {
        color: #3669A8;
      }
    }
  }
}

.menu-button {
  transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.navigation-drawer {
  .active {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
  }
}



